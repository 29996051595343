<template>
  <div>
    <IntroSection
        text=" Welcome to my Nature Photography Collection!<br><br>
               I invite you to enjoy these images of the birds and animals that frequent my South Western Ontario backyard.<br><br>
               I capture their beauty with my Fuji XT2 which is always at the ready. The close-ups are thanks to my two zoom lenses, a 50-230 and an 18-135.<br><br>
               I share my space with nature's finest!"
    ></IntroSection>
    <Gallery
        :images="media"
        category="Nature photograph"
    />
  </div>
</template>
<script>
import Preloader from "@/components/Preloader";
import IntroSection from "@/components/IntroSection";
import Gallery from "@/components/Gallery";
import image1 from "@/assets/images/photography/photography-1.jpg";   //baltimore oriole on the birdbath hosta
import image2 from "@/assets/images/photography/photography-2.jpg";   //chipmunk on a stump
import image3 from "@/assets/images/photography/photography-3.jpg";   //wide-eyed Cooper's Hawk in the birdbath
import image4 from "@/assets/images/photography/photography-4.jpg";   //Blue Jay peeking
import image5 from "@/assets/images/photography/photography-5.jpg";   //Blue Jay looking into the chickadee birdhouse
import image6 from "@/assets/images/photography/photography-6.jpg";   //fox having a drink at the birdbath - pink tongue
import image7 from "@/assets/images/photography/photography-7.jpg";   //handsome fox resting on the front lawn
import image8 from "@/assets/images/photography/photography-8.jpg";   //fox in the hostas
import image9 from "@/assets/images/photography/photography-9.jpg";   //fox head in the hostas
import image10 from "@/assets/images/photography/photography-10.jpg"; //fox jumped up onto the front birdbath
import image11 from "@/assets/images/photography/photography-11.jpg"; //fox at his foxhole in the ravine
import image12 from "@/assets/images/photography/photography-12.jpg"; //squirrel in the ivy
import image13 from "@/assets/images/photography/photography-13.jpg"; //raccoon one paw on birdbath
import image14 from "@/assets/images/photography/photography-14.jpg"; //pair of downy woodpeckers at the feeder
import image15 from "@/assets/images/photography/photography-15.jpg"; //fat blue jay in the tree
import image16 from "@/assets/images/photography/photography-16.jpg"; //pair of nuthatches at the birdhouse
import image17 from "@/assets/images/photography/photography-17.jpg"; //two curious nuthatches at the birdhouse
import image18 from "@/assets/images/photography/photography-18.jpg"; //raccoon at birdbath
import image19 from "@/assets/images/photography/photography-19.jpg"; //raccoon fishing
import image20 from "@/assets/images/photography/photography-20.jpg"; //raccoon reflections
import image21 from "@/assets/images/photography/photography-21.jpg"; //cooper's hawk backside
import image22 from "@/assets/images/photography/photography-22.jpg"; //cooper's hawk on the line
import image23 from "@/assets/images/photography/photography-23.jpg"; //fox sees me
import image24 from "@/assets/images/photography/photography-24.jpg"; //raccoon sees me
import image25 from "@/assets/images/photography/photography-25.jpg"; //woodpecker peek-a-boo
import image26 from "@/assets/images/photography/photography-26.jpg"; //cardinal on white
import image27 from "@/assets/images/photography/photography-27.jpg"; //finches on ice
import image28 from "@/assets/images/photography/photography-28.jpg"; //doves with blue accents
import image29 from "@/assets/images/photography/photography-29.jpg"; //red accents pool party
import image30 from "@/assets/images/photography/photography-30.jpg"; //pool party in the blue birdbath
import image31 from "@/assets/images/photography/photography-31.jpg"; //black crow in the birdbath
import image32 from "@/assets/images/photography/photography-32.jpg"; //feisy female cardinal
import image33 from "@/assets/images/photography/photography-33.jpg"; //finch feasting
import image34 from "@/assets/images/photography/photography-34.jpg"; //junko in the autumn leaves
import image35 from "@/assets/images/photography/photography-35.jpg"; //female cardinal
import image36 from "@/assets/images/photography/photography-36.jpg"; //cardinal meeting
import image37 from "@/assets/images/photography/photography-37.jpg"; //busy feeder
import image38 from "@/assets/images/photography/photography-38.jpg"; //cardinal with pink accents
import image39 from "@/assets/images/photography/photography-39.jpg"; //2 doves in the bath
import image40 from "@/assets/images/photography/photography-40.jpg"; //northern flicker by the epimedium
import image41 from "@/assets/images/photography/photography-41.jpg"; //rare bird on the balcony
import image42 from "@/assets/images/photography/photography-42.jpg"; //hummingbird at the disc feeder
import image43 from "@/assets/images/photography/photography-43.jpg"; //hummingbird having a drink
import image44 from "@/assets/images/photography/photography-44.jpg"; //hummingbird coming in for a landing
import image45 from "@/assets/images/photography/photography-45.jpg"; //gros beak and a cardinal
import image46 from "@/assets/images/photography/photography-46.jpg"; //hummingbird and blue dots
import image47 from "@/assets/images/photography/photography-47.jpg"; //hummingbird in the tree
import image48 from "@/assets/images/photography/photography-48.jpg"; //scruffy male cardinal
import image49 from "@/assets/images/photography/photography-49.jpg"; //cardinal in the mulberry
import image50 from "@/assets/images/photography/photography-50.jpg"; //splashing blue jays
import image51 from "@/assets/images/photography/photography-51.jpg"; //fox and a pink tongue
import image52 from "@/assets/images/photography/photography-52.jpg"; //fox in the snow
import image53 from "@/assets/images/photography/photography-53.jpg"; //woodpecker in a flurry
import image54 from "@/assets/images/photography/photography-54.jpg"; //little one on the bamboo
import image55 from "@/assets/images/photography/photography-55.jpg"; //finch and a carolina wren
import image56 from "@/assets/images/photography/photography-56.jpg"; //wren in the snow
import image57 from "@/assets/images/photography/photography-57.jpg"; //finch in the cedar
import image58 from "@/assets/images/photography/photography-58.jpg"; //fat cardinal
import image59 from "@/assets/images/photography/photography-59.jpg"; //two cocky cardinals
import image60 from "@/assets/images/photography/photography-60.jpg"; //3 cocky cardinals
import image61 from "@/assets/images/photography/photography-61.jpg"; //tri-colour
import image62 from "@/assets/images/photography/photography-62.jpg"; //two blue jays up-close
import image63 from "@/assets/images/photography/photography-63.jpg"; //two jays
import image64 from "@/assets/images/photography/photography-64.jpg"; //blue jay at the bath
import image65 from "@/assets/images/photography/photography-65.jpg"; //robin at the bath
import image66 from "@/assets/images/photography/photography-66.jpg"; //squawking blue jay
import image67 from "@/assets/images/photography/photography-67.jpg"; //molting bue jay
import image68 from "@/assets/images/photography/photography-68.jpg"; //berry in beak
import image69 from "@/assets/images/photography/photography-69.jpg"; //wet starling
import image70 from "@/assets/images/photography/photography-70.jpg"; //sibling raccoons
import image71 from "@/assets/images/photography/photography-71.jpg"; //two raccoons and a tail
import image72 from "@/assets/images/photography/photography-72.jpg"; //baltimore orioles
import image73 from "@/assets/images/photography/photography-73.jpg"; //two orioles and  a nuthatch
import image74 from "@/assets/images/photography/photography-74.jpg"; //two female gros beaks at the cylinder
import image75 from "@/assets/images/photography/photography-75.jpg"; //cooper's hawk in the birdbath
import image76 from "@/assets/images/photography/photography-76.jpg"; //deer with scars
import image77 from "@/assets/images/photography/photography-77.jpg"; //northern flicker in the mountain ash
import image78 from "@/assets/images/photography/photography-78.jpg"; //nuthatch on the bamboo
import image79 from "@/assets/images/photography/photography-79.jpg"; //great-horned owl sitting on our fence
import image80 from "@/assets/images/photography/photography-80.jpg"; //chipmunk with stuffed cheeks
import image81 from "@/assets/images/photography/photography-81.jpg"; //hummingbird perched on a turtlehead flower
import image82 from "@/assets/images/photography/photography-82.jpg"; //hummingbird hovering over a turtlehead
import image83 from "@/assets/images/photography/photography-83.jpg"; //redtail hawk eating a squirrel
import image84 from "@/assets/images/photography/photography-84.jpg"; //hawk with squirrel in claws
import image85 from "@/assets/images/photography/photography-85.jpg"; //redtail hawk in the birdbath
import image86 from "@/assets/images/photography/photography-86.jpg"; //hummingbird with fluttering wings
import image87 from "@/assets/images/photography/photography-87.jpg"; //hummingbird landed on turtlehead
import image88 from "@/assets/images/photography/photography-88.jpg"; //bird nest found in the impatiens on balcony
import image89 from "@/assets/images/photography/photography-89.jpg"; //a pair of hawks
import image90 from "@/assets/images/photography/photography-90.jpg"; //hawk having lunch
import image91 from "@/assets/images/photography/photography-91.jpg"; //dove with an ant
import image92 from "@/assets/images/photography/photography-92.jpg"; //mean-looking fox
import image93 from "@/assets/images/photography/photography-93.jpg"; //fox protecting his catch
import image94 from "@/assets/images/photography/photography-94.jpg"; //snarling fox
import image95 from "@/assets/images/photography/photography-95.jpg";


export default {
  components: {Preloader, Gallery, IntroSection},
  data() {
    return {
      media: {
        images: [
          image1,
          image2,
          image3,
          image4,
          image5,
          image6,
          image7,
          image8,
          image9,
          image10,
          image11,
          image12,
          image13,
          image14,
          image15,
          image16,
          image17,
          image18,
          image19,
          image20,
          image21,
          image22,
          image23,
          image24,
          image25,
          image26,
          image27,
          image28,
          image29,
          image30,
          image31,
          image32,
          image33,
          image34,
          image35,
          image36,
          image37,
          image38,
          image39,
          image40,
          image41,
          image42,
          image43,
          image44,
          image45,
          image46,
          image47,
          image48,
          image49,
          image50,
          image51,
          image52,
          image53,
          image54,
          image55,
          image56,
          image57,
          image58,
          image59,
          image60,
          image61,
          image62,
          image63,
          image64,
          image65,
          image66,
          image67,
          image68,
          image69,
          image70,
          image71,
          image72,
          image73,
          image74,
          image75,
          image76,
          image77,
          image78,
          image79,
          image80,
          image81,
          image82,
          image83,
          image84,
          image85,
          image86,
          image87,
          image88,
          image89,
          image90,
          image91,
          image92,
          image93,
          image94,
          image95

        ],
        texts: [
          "baltimore oriole on the birdbath hosta",
          "chipmunk on a stump",
          "wide-eyed Cooper's Hawk in the birdbath",
          "Blue Jay peeking",
          "Blue Jay looking into the chickadee birdhouse",
          "fox having a drink at the birdbath - pink tongue",
          "handsome fox resting on the front lawn",
          "fox in the hostas",
          "fox head in the hostas",
          "fox jumped up onto the front birdbath",
          "fox at his foxhole in the ravine",
          "squirrel in the ivy",
          "raccoon one paw on birdbath",
          "pair of downy woodpeckers at the feeder",
          "fat blue jay in the tree",
          "pair of nuthatches at the birdhouse",
          "two curious nuthatches at the birdhouse",
          "raccoon at birdbath",
          "raccoon fishing",
          "raccoon reflections",
          "cooper's hawk backside",
          "cooper's hawk on the line",
          "fox sees me",
          "raccoon sees me",
          "woodpecker peek-a-boo",
          "cardinal on white",
          "finches on ice",
          "doves with blue accents",
          "red accents pool party",
          "pool party in the blue birdbath",
          "black crow in the birdbath",
          "feisy female cardinal",
          "finch feasting",
          "junko in the autumn leaves",
          "female cardinal",
          "cardinal meeting",
          "busy feeder",
          "cardinal with pink accents",
          "2 doves in the bath",
          "northern flicker by the epimedium",
          "rare bird on the balcony",
          "hummingbird at the disc feeder",
          "hummingbird having a drink",
          "hummingbird coming in for a landing",
          "gros beak and a cardinal",
          "hummingbird and blue dots",
          "hummingbird in the tree",
          "scruffy male cardinal",
          "cardinal in the mulberry",
          "splashing blue jays",
          "fox and a pink tongue",
          "fox in the snow",
          "woodpecker in a flurry",
          "little one on the bamboo",
          "finch and a carolina wren",
          "wren in the snow",
          "finch in the cedar",
          "fat cardinal",
          "two cocky cardinals",
          "3 cocky cardinals",
          "tri-colour",
          "two blue jays up-close",
          "two jays",
          "blue jay at the bath",
          "robin at the bath",
          "squawking blue jay",
          "molting bue jay",
          "berry in beak",
          "wet starling",
          "sibling raccoons",
          "two raccoons and a tail",
          "baltimore orioles",
          "two orioles and a nuthatch",
          "two female gros beaks at the cylinder",
          "cooper's hawk in the birdbath",
          "deer with scars",
          "northern flicker in the mountain ash",
          "nuthatch on the bamboo",
          "great-horned owl sitting on our fence",
          "chipmunk with stuffed cheeks",
          "hummingbird perched on a turtlehead flower",
          "hummingbird hovering over a turtlehead",
          "redtail hawk eating a squirrel",
          "hawk with squirrel in claws",
          "redtail hawk in the birdbath",
          "hummingbird with fluttering wings",
          "hummingbird landed on turtlehead",
          "bird nest found in the impatiens on balcony",
           "a pair of hawks          ",
           "hawk having lunch        ",
           "dove with an ant         ",
           "mean-looking fox         ",
           "fox protecting his catch ",
           "snarling fox",
           "",
        ]
      }
    }
  },
  created() {
    const metaDescription = this.$route.meta.metaDescription;
    document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);
    const metaTitle = this.$route.meta.title;
    const titleElement = document.querySelector('title');
    titleElement.textContent = metaTitle;

  }
}
</script>