import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FlowersView from "@/views/FlowersView";
import FacesView from "@/views/FacesView";
import HousesView from "@/views/HousesView";
import MiscView from "@/views/MiscView";
import AvailableWorks from "@/views/AvailableWorksView";
import Contact from "@/views/ContactView";
import Photography from "@/views/PhotographyView";

const routes = [
    {
        path: '/',
        redirect: '/available-works',
    },
    {
        path: '/available-works',
        name: 'available-works',
        component: AvailableWorks,
        meta: {
            title: " Available Art for Purchase - Judy Carmichael Art & Photography",
            metaDescription: "Browse Judy Carmichael's available art works. Explore a curated selection of pieces ready to bring inspiration and beauty to your space.",
        }
    },
    {
        path: '/photography',
        name: 'photography',
        component: Photography,
        meta: {
            title: "Nature Photography - Judy Carmichael Art & Photography",
            metaDescription: "Step into the enchanting world of Judy Carmichael's backyard wildlife photography. Witness the beauty of foxes, birds, and more of nature's wonders in every season."
        }
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact,
        meta: {
            title: "Get in Touch with Judy Carmichael's Art",
            metaDescription: "Explore Judy Carmichael's stunning floral artworks. Immerse yourself in a world of vibrant blooms and artistic beauty."
        }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router
