<template>
  <div class="contact" id="contact" ref="contact">
    <div class="container">
    <form ref="form" id="form" @submit.prevent="sendEmail" >
      <div class="form-content">
        <div class="form-text">
          <h2>Let's Chat!</h2>
          <p>
            Thank you for visiting my artistic space! I'm thrilled to have piqued your interest and would love to hear from you.
            <br><br>
            Whether you have a question or are interested in purchasing, this is the perfect place to connect.
            <br><br>
          </p>
        </div>
        <div class="form-group form-group-half">
          <input
              id="name"
              name="name"
              type="text"
              v-model="name"
              placeholder="Name"
              required
          >
          <input
              id="email"
              type="email"
              v-model="email"
              name="email"
              placeholder="Email"
          >
          <textarea
              placeholder="Message"
              id="message"
              class="form-group"
              v-model="message">
          </textarea>
          <input class="submit" type="submit" value="Send" id="submit" ref="submit">
        </div>
      </div>
      <div class="thank-you" id="thank-you" ref="thank-you">
        <p>Message Sent! I will be in touch within 48 hours.</p>
      </div>
    </form>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  name: 'ContactMe',
  data() {
    return {
      name: '',
      email: '',
      message: ''
    }
  },
  created() {
    const metaDescription = this.$route.meta.metaDescription;
    document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);
    const metaTitle = this.$route.meta.title;
    const titleElement = document.querySelector('title');
    titleElement.textContent = metaTitle;
  },
  methods: {
    sendEmail() {
      try {
        emailjs.send(process.env.VUE_APP_serviceID, process.env.VUE_APP_templateID,
            {
              name: this.name,
              email: this.email,
              message: this.message,
            }, process.env.VUE_APP_userID)

        this.$refs.contact.classList.add("success");

      } catch(error) {
        console.log({error})
      }
      this.name = '';
      this.email = '';
      this.message = '';
    },
    showForm() {
      this.$refs.contact.classList.remove("success");
      this.$refs.submit.value = "Send";
    }
  }
}
</script>

<style lang="scss">
.contact {
  height: calc(100vh - 150px);
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #f5f4f0;
  form {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;

    h2 {
      width: 100%;
      padding-bottom: 2.25rem;
      text-align: left;
      margin: 0;
    }

    p {
      margin: 0;
    }

    .form-content {
      display: flex;
      gap: 2rem;
      .form-text {
        width: 40%;
        text-align: left;
      }

      .form-group {
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
        font-family: Lato, sans-serif !important;
        font-weight: 400 !important;

        input:not([type="submit"]) {
          max-width: 97%;
          width: 97%;
          margin-bottom: 1rem;
          padding: 1rem;
          box-sizing: border-box;
          font-family: Lato, sans-serif !important;
          font-weight: 700 !important;
        }

        textarea {
          resize: vertical;
          padding: 1rem 1rem 5rem;
          height: 85%;
          max-width: 97%;
          width: 97%;
          box-sizing:border-box;
          margin-bottom: 1rem;
          font-family: Lato, sans-serif !important;
          font-weight: 700 !important;
        }

        .submit {
          padding: 0.5rem 2rem;
          cursor: pointer;
        }
      }
    }
    .thank-you {
      position: absolute;
      bottom: 10px;
      left: 10px;
      font-size: 14px;
      display: none;
    }
  }

  &.success {
    .thank-you {
      display: block;
    }
  }
}


@include for-tablet-landscape-up {
  .contact {
    form {
      max-width: 50rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@include for-phone-only {
  .contact {
    padding-top: 2rem;
    height: 100%;
    form {
      padding-bottom: 3rem;
      h2 {
        text-align: center;
        padding-bottom: 1.5rem;
      }
      .form-content {
        flex-direction: column;
        gap: 1rem;
        .form-text, .form-group {
          width: 100%;
        }
        .form-text {
          text-align: center;
        }

        .submit {
          width: 97%;
        }
      }
      .thank-you {
        bottom: 5px;
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      }
    }
  }
}
</style>
