<template>
  <div class="search" :class="{ showSearch: showSearch }">
      <div class="container">
        <div class="search-container">
          <input @focus="showSearch = true" @blur="showSearch = false" class="search-bar" placeholder="Search titles..." type="text" v-model="searchText"/>
        </div>
      </div>
    </div>
</template>

<script>
import flexGallery from "@/assets/js/flex-gallery.js";
import $ from "jquery";
export default {
  name: "Search",
  data() {
    return {
      searchText: '',
      showSearch: false
    }
  },
  props: {
    images: {}
  },
  watch: {
    searchText(newText) {
      let newTextLower = newText.toLowerCase();
      let images = [];
      let texts = [];
      let indexes = [];

      this.images.texts.filter(function(title,i,rep){
        let lowerCaseTitle = title.toLowerCase();
        if(lowerCaseTitle.includes(newTextLower)) {
          indexes.push(i);
          return i;
        }
      });

      for (var i = 0; i < indexes.length; i++) {
        images.push(this.images.images[indexes[i]]);
        texts.push(this.images.texts[indexes[i]]);
      }

      let media = {
        images: images,
        texts: texts
      };

      this.renderFilteredImages(media);

    }
  },
  methods: {
    renderFilteredImages(media) {

      document.getElementById('container').remove();
      const filteredImages = document.createElement("div");
      filteredImages.id = 'container';
      document.getElementById('gallery').appendChild(filteredImages);

      $('#container').addFlexImages(media, true).flexGallery({
        margin:'0.7vmin',
        minHeightRatioWindow:0.4,
        minHeightRatioScreen:0.4,
        fadeInDuration: 1200,
        checkPeriod: 100,
        autoAdjust: true
      });
    }
  }
}
</script>

<style lang="scss">
.search {
  position: absolute;
  top: 20px;
  right: 0;
  .search-container {
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 0.5rem;
    .search-icon {
      height: 1.3rem;
      width: auto;
      cursor: pointer;
    }
    .search-bar {
      cursor: pointer;
      width: 6rem;
      opacity: 1;
      padding: 0.45rem 0.75rem 0.4rem 2rem;
      transition: all 0.3s ease-out;
      background: url(../assets/images/icons/icon-search-black-2.svg) no-repeat left;
      background-position-x: 7px;
      background-size: 20px;
      border-radius: 15px;
      border: 2px black solid;
    }
  }
  &.showSearch {
    .search-container {
      .search-bar {
        width: 15rem;
        opacity: 1;
      }
    }
  }

  @media (max-width: 1010px) {
   display: none;
  }
}

</style>