<template>
  <div class="navbar" :class="{ show: showMenu }">
    <div class="container">
      <a href="https://www.judycarmichaelart.com/">
        <h1>JUDY CARMICHAEL</h1>
      </a>
      <div class="mobile-menu-container">
        <span class="menu-heading">MENU</span>
        <div class="hamburger" v-on:click="toggleMenu">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="navbar-container">
        <ul class="nav" v-on:click="toggleMenu">
          <li><router-link class="link" to="/available-works">Available Artworks</router-link></li>
          <!-- <li><router-link class="link" to="/floral">Floral</router-link></li>
          <li><router-link class="link" to="/structures">Structures</router-link></li>
          <li><router-link class="link" to="/faces">Faces</router-link></li>
          <li><router-link class="link" to="/miscellaneous">Miscellaneous</router-link></li> -->
          <li><router-link class="link" to="/photography">My Backyard Photography</router-link></li>
          <li><router-link class="link" to="/contact">Contact</router-link></li>
        </ul>
      </div>
    </div>
  </div>
  <router-view/>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      showMenu: false
    }
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
      let html = document.getElementsByTagName( 'html' )[0]

      if(this.showMenu) {
        html.classList.add('mobile-menu-show');
      }
      else {
        html.classList.remove('mobile-menu-show');
      }
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Chorasmian&family=Noto+Sans:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Noto+Sans+Chorasmian&family=Noto+Sans:wght@200&display=swap');
.navbar {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .mobile-menu-container {
      display: flex;
      gap: 10px;
      align-items: center;
      .menu-heading {
        font-weight: bold;
        font-size: 16px;
      }
    }
    h1 {
      font-family: 'Noto Sans', sans-serif;
      color: $black;
      font-weight: bold !important;
      letter-spacing: 5px;
      font-size: 16px;
      margin: 0;
      cursor: pointer;
      padding: 0;
    }
    .logo-link {
      padding: 0;
    }
    .logo {
      height: 2.2rem;
      width: auto;
    }
    .navbar-container {
      position: absolute;
      z-index: 999;
      background-color: white;
      top: 64px;
      left: 999px;
      height: 100vh;
      width: 100%;
      padding-top: 10vh;
      transition: all 0.4s ease-out;
      .nav {
        display: flex;
        flex-direction: column;
        row-gap: 1.5rem;
        margin: 10px 0;
        .router-link-active {
          &::after {
            background-color: $black;
          }
        }
        a {
          font-size: 13px;
          color: $offBlack;
          text-transform: uppercase;
          font-family: 'Lato', sans-serif;
          font-weight: bold;
          letter-spacing: 2px;
          &:hover {
            color: $black;
            font-weight: bolder;
          }
        }
      }
    }

    .hamburger {
      display: block;
      width: 30px;
      height: 20px;
      position: relative;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .3s ease-in-out;
      -moz-transition: .3s ease-in-out;
      -o-transition: .3s ease-in-out;
      transition: .3s ease-in-out;
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: $black;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;

        &:nth-child(1) {
          top: 0px;
        }

        &:nth-child(2), &:nth-child(3) {
          top: 8px;
        }

        &:nth-child(4)  {
          top: 16px;
        }
      }
    }
  }

  &.show {
    .container {
      .navbar-container {
        right: 0;
        left: 0;
      }
    }
  }

}

@media (min-width: 1010px) {
  .navbar {
    padding-top: 3.125rem;
    padding-bottom: 0.5rem;
    .container {
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;

      h1 {
        padding-bottom: 0.75rem;
        font-size: 22px;
      }
      .navbar-container {
        position: static;
        height: unset;
        width: unset;
        padding-top: unset;
        .nav {
          display: flex;
          flex-direction: row;
          column-gap: 3rem;
        }
      }
      .mobile-menu-container {
        display: none;
      }
    }
    .mobile-heading {
      display: none;
    }
  }
}

</style>
