<template>
  <div v-on:click="backToTop" id="backToTopBtn">
    <img class="logo" :src="require(`../assets/images/icons/arrow-up.svg`)">
  </div>
</template>

<script>
export default {
  name: "BackToTopButton",
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      let button = document.getElementById("backToTopBtn");
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        button.style.display = "block";
      } else {
        button.style.display = "none";
      }
    },
    backToTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }
}
</script>

<style lang="scss">
#backToTopBtn {
  color: $white;
  display: none;
  position: fixed;
  bottom: 15px;
  right: 45px;
  left: auto;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  cursor: pointer;
  height: 4rem;
  width: auto;

  img {
    width: 100%;
    height: 100%;
    transition: all 0.15s ease-in;

    &:hover {
      transform: translateY(-15px);
    }
  }
}

@include for-phone-only {
  #backToTopBtn {
    right: 5px;
    height: 3rem;
    width: auto;
  }
}
</style>