<template>
  <div class="modal" @click="$emit('close')">
    <div class="modal-content">
      <button class="close" @click="$emit('close')">
        <svg class="desktop-hide"  fill="#000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="20px" height="20px"><path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"/></svg>
        <svg class="mobile-hide" fill="#000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="30px" height="30px"><path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"/></svg>
      </button>
      <img class="modal-image">
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageModalDynamic",
  props: {
    image: String,
  },
  data() {
    return {
      isModalVisible: false
    };
  }
}
</script>

<style lang="scss">

.modal {
  z-index: 99999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;

  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2.5rem 2.5rem;
    background-color: white;

    .close {
      position: absolute;
      right: 5px;
      top: 5px;
      padding: 0;
      cursor: pointer;

      svg {
        max-height: 100%;
        transition: fill .4s ease;

        &:hover, &:focus {
          fill: $offBlack;
        }
      }

    }

    img {
      max-height: calc(100vh - 200px);
      max-width: unset;
      height: auto;
      box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important;

      @media (max-width: 800px) {
        max-width: 100%;

      }
    }

    &.image-tall {

    }
  }
}

.modal-active {
  .modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
  }
}

@include for-tablet-portrait-down {
  .modal {
    .modal-content {
      width: 80%;
      max-width: 80%;
      .close {
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }
  }
}

@include for-phone-only {
  .modal {
    .modal-content {
      padding: 1.75rem;
    }
  }
}
</style>