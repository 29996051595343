<template>
  <section class="flip-grid" ref="target" :class="{ right: imageRight, tall: tall }" :data-artwork-width="width" :data-artwork-height="height">
    <div class="container">
      <div class="flip-row">
        <div v-on:click="showModal('whatever', $event)"  class="image-container">
          <img class="art-image" v-bind:src=imageSrc :alt="imageTitle + ' - ' + toTitleCase(imageMedium)" />
        </div>
        <div class="content">
          <h2 class="image-title">{{imageTitle}}</h2>
          <h3 class="image-dimensions">{{imageDimensions}}</h3>
          <h3 class="image-medium">{{ imageMedium }}</h3>
          <h3 class="image-substrate">{{ imageSubstrate }}</h3>
          <h3 class="image-framing-required" v-if="framingRequired">Framing Required</h3>
          <h3 class="image-price" v-if="imagePrice">{{ imagePrice }}</h3>
          <h3 class="image-sold" v-if="sold">SOLD</h3>
        </div>
      </div>
    </div>
    <ImageModal
        :image="imageSrc"
        @close="closeModal"
    />
  </section>
</template>

<script>
import { useElementVisibility } from '@vueuse/core'
import { ref } from 'vue'
import ImageModal from "@/components/ImageModal";

export default {
  name: "FlipGrid",
  components: {ImageModal},
  props: {
    imageRight: Boolean,
    imageSrc: String,
    imageTitle: String,
    imageDimensions: String,
    imageSubstrate: String,
    imageMedium: String,
    imagePrice: String,
    framingRequired: Boolean,
    tall: Boolean,
    height: Number,
    width: Number,
    sold: Boolean
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    showModal(whatever, event) {
      this.isModalVisible = true;
      let imageClicked = event.target;
      let flipGrid = imageClicked.closest('.flip-grid');
      flipGrid.classList.add('modal-active');
      document.body.classList.add('no-scroll');
      document.documentElement.classList.add('no-scroll');
    },

    closeModal() {
      this.isModalVisible = false;
      let elems = document.querySelectorAll('.modal-active');
      [].forEach.call(elems, function(el) {
        el.classList.remove('modal-active');
      });

      document.body.classList.remove('modal-active');
      document.body.classList.remove('no-scroll');
      document.documentElement.classList.remove('no-scroll');
    },

    handleEmitProp(prop) {
      this.$emit('emitProp', prop);
    },
    toTitleCase(input) {
      return input
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
    }
  }
}
</script>

<style lang="scss">
.flip-grid {
  padding-top: 0;
  .container {
    max-width: 40rem;
    .flip-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 3rem;
      gap: 3rem;

      .image-container {
        max-height: calc(100vh - 200px);
        max-width: 55%;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
          max-height: calc(100vh - 200px);
          box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important;
        }
      }

      .content {
        text-align: right;
        max-width: 50%;

        h2:not(.image-dimensions) {
          text-transform: capitalize;
        }

        .image-title {
          font-weight: bold;
        }
      }
    }
  }

  &.right {
    .container {
      .flip-row {
        .image-container {
          order: 1;
        }
        .content {
          order: 0;
          text-align: left;
        }
      }
    }
  }
}

@include for-tablet-portrait-down {
  .flip-grid {
    &:first-of-type {
      .container {
        .flip-row {
          max-height: calc(100vh - 150px);
          height: calc(100vh - 150px);
        }
      }
    }
    &.tall {
      .container {
        .flip-row {
          .image-container {
            max-width: 50% !important;
          }
        }
      }
    }
    .container {
      .flip-row {
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        padding-bottom: 0;
        max-height: calc(100vh - 100px);
        height: calc(100vh - 100px);
        .image-container {
          width: auto;
          height: auto;
          max-height: 75%;
          max-width: 100%;
          img {
            max-width: 100%;
            height: 100%;
          }
        }

        .content {
          text-align: center;
          max-width: 100% !important;
          width: 100%;
          padding-bottom: 0;
        }
      }
    }

    &.right {
      .container {
        .flip-row {
          .image-container {
            order: 0;
          }
          .content {
            order: 1;
            text-align: center;
          }
        }
      }
    }
  }
}

@include for-desktop-up {
  .flip-grid {
    .container {
      max-width: 45rem;
    }
  }
}

@include for-big-desktop-up {
  .flip-grid {
    .container {
      max-width: 57rem;
      .flip-row {
        .image-container {
          max-height: calc(100vh - 220px);
        }
      }
    }
  }
}

</style>