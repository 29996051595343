<template>
  <Search :images= "this.images"/>
  <div class="gallery" id="gallery">
      <div id="container"></div>
    <BackToTopButton/>
    <ImageModalDynamic
        @close="closeModal"
    />
  </div>
</template>
<script>
import $ from 'jquery';
import flexGallery from "@/assets/js/flex-gallery.js";
import BackToTopButton from "@/components/BackToTopButton";
import Search from "@/components/Search";
import ImageModalDynamic from "@/components/ImageModalDynamic";

export default {
  name: "Gallery",
  components: {ImageModalDynamic, Search, BackToTopButton, flexGallery},
  data() {
    return {
      imageUrls: Array,
    }
  },
  props: {
    imageSrc: String,
    imageTitle: String,
    images: {},
    category: String
  },
  mounted() {
    $('#container').addFlexImages(this.images, true, this.category).flexGallery({
      margin:'0.5vmin',
      minHeightRatioWindow: 0.4,
      minHeightRatioScreen: 0.4,
      fadeInDuration: 1200,
      checkPeriod: 100,
      autoAdjust: true
    });
  },
  watch: {
    searchText(newText) {
      let newTextLower = newText.toLowerCase();
      let images = [];
      let texts = [];
      let indexes = [];

      this.images.texts.filter(function(title,i,rep){
        let lowerCaseTitle = title.toLowerCase();
        if(lowerCaseTitle.includes(newTextLower)) {
          indexes.push(i);
          return i;
        }
      });

      for (var i = 0; i < indexes.length; i++) {
          images.push(this.images.images[indexes[i]]);
          texts.push(this.images.texts[indexes[i]]);
      }

      let media = {
        images: images,
        texts: texts
      };

      this.renderFilteredImages(media);

    }
  },
  methods: {
    renderFilteredImages(media) {

      document.getElementById('container').remove();
      const filteredImages = document.createElement("div");
      filteredImages.id = 'container';
      document.getElementById('gallery').appendChild(filteredImages);

      $('#container').addFlexImages(media, false).flexGallery({
        margin:'1.5vmin',
        minHeightRatioWindow:1,
        minHeightRatioScreen:0.4,
        fadeInDuration: 1200,
        checkPeriod: 100,
        autoAdjust: true
      });
    },
    closeModal() {
      this.isModalVisible = false;
      let elems = document.querySelectorAll('.modal-active');
      [].forEach.call(elems, function(el) {
        el.classList.remove('modal-active');
      });
      let modalImage = document.querySelector('.modal-image');
      if(modalImage) {
        modalImage.src = '';
      }
      document.body.classList.remove('modal-active');
      document.body.classList.remove('no-scroll');
      document.documentElement.classList.remove('no-scroll');
    },
  }
}
</script>

<style lang="scss">
.gallery {
  padding-bottom: 100px;
  padding-right: 16px;
  padding-left: 16px;
  position: relative;
}

.fg-container {
  display: flex;
  flex-wrap: wrap;
}
.fg-container::after {
  /**
   * Creates a blank element after the last image.
   */
  content: '';
  /**
   * Prevents the images in the last row to grow.
   * In case they become too large.
   */
  flex-grow: 99999;
  /**
   * Well... If the left space is less than (screenHeight / 4), make them grow!
   */
  min-width: calc(100vw / 4);
}
.fg-item {
  position: relative;
  cursor: pointer;

  a {
    &::after {
      display: none;
    }
  }
}
.fg-img {
  user-select: none;
  width: 100%;
  vertical-align: middle;
}
.fg-text {
  position: absolute;
  left: 1em;
  bottom: 1em;
  z-index: 2;
  color: white;
  text-transform: capitalize;
}

@media (max-width: 999px) {
  .fg-item {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media (max-width: 1050px) {

  .fg-container {
    max-width: 800px;
    margin-left: auto !important;
    margin-right: auto !important;

    .fg-item {
      width: 100% !important;
    }
  }
}
</style>