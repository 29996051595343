<template>
  <div class="preloader-container">
    <div class="preloader-floating-circles">
      <div class="f_circleG" id="frotateG_01"></div>
      <div class="f_circleG" id="frotateG_02"></div>
      <div class="f_circleG" id="frotateG_03"></div>
      <div class="f_circleG" id="frotateG_04"></div>
      <div class="f_circleG" id="frotateG_05"></div>
      <div class="f_circleG" id="frotateG_06"></div>
      <div class="f_circleG" id="frotateG_07"></div>
      <div class="f_circleG" id="frotateG_08"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Preloader"
}
</script>

<style lang="scss">

.preloader-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: rgba(255,255,255,0.8);
  z-index: 10;
  padding-top: 40vh;
  opacity: 1;
  transition: opacity ease-out 0.5s;
}

.preloader-container.preloader-hide {
   opacity: 0;
}

#spinner {
  display: block;
  height: 110px;
  left: 50%;
  margin: -55px;
  position: relative;
  top: 50%;
  width: 110px;
}

#preloader #disable-preloader {
  display: block;
  position: absolute;
  right: 30px;
  bottom: 30px;
}

.preloader-floating-circles {
  position: relative;
  width: 80px;
  height: 80px;
  transform: scale(0.6);
  -o-transform: scale(0.6);
  -ms-transform: scale(0.6);
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  .f_circleG {
    position: absolute;
    background-color: rgb(255, 255, 255);
    height: 14px;
    width: 14px;
    border-radius: 7px;
    -o-border-radius: 7px;
    -ms-border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    animation-name: f_fadeG;
    -o-animation-name: f_fadeG;
    -ms-animation-name: f_fadeG;
    -webkit-animation-name: f_fadeG;
    -moz-animation-name: f_fadeG;
    animation-duration: 0.672s;
    -o-animation-duration: 0.672s;
    -ms-animation-duration: 0.672s;
    -webkit-animation-duration: 0.672s;
    -moz-animation-duration: 0.672s;
    animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-direction: normal;
    -o-animation-direction: normal;
    -ms-animation-direction: normal;
    -webkit-animation-direction: normal;
    -moz-animation-direction: normal;
  }
  #frotateG_01 {
    left: 0;
    top: 32px;
    animation-delay: 0.2495s;
    -o-animation-delay: 0.2495s;
    -ms-animation-delay: 0.2495s;
    -webkit-animation-delay: 0.2495s;
    -moz-animation-delay: 0.2495s;
  }
  #frotateG_02 {
    left: 9px;
    top: 9px;
    animation-delay: 0.336s;
    -o-animation-delay: 0.336s;
    -ms-animation-delay: 0.336s;
    -webkit-animation-delay: 0.336s;
    -moz-animation-delay: 0.336s;
  }
  #frotateG_03 {
    left: 32px;
    top: 0;
    animation-delay: 0.4225s;
    -o-animation-delay: 0.4225s;
    -ms-animation-delay: 0.4225s;
    -webkit-animation-delay: 0.4225s;
    -moz-animation-delay: 0.4225s;
  }
  #frotateG_04 {
    right: 9px;
    top: 9px;
    animation-delay: 0.509s;
    -o-animation-delay: 0.509s;
    -ms-animation-delay: 0.509s;
    -webkit-animation-delay: 0.509s;
    -moz-animation-delay: 0.509s;
  }
  #frotateG_05 {
    right: 0;
    top: 32px;
    animation-delay: 0.5955s;
    -o-animation-delay: 0.5955s;
    -ms-animation-delay: 0.5955s;
    -webkit-animation-delay: 0.5955s;
    -moz-animation-delay: 0.5955s;
  }
  #frotateG_06 {
    right: 9px;
    bottom: 9px;
    animation-delay: 0.672s;
    -o-animation-delay: 0.672s;
    -ms-animation-delay: 0.672s;
    -webkit-animation-delay: 0.672s;
    -moz-animation-delay: 0.672s;
  }
  #frotateG_07 {
    left: 32px;
    bottom: 0;
    animation-delay: 0.7585s;
    -o-animation-delay: 0.7585s;
    -ms-animation-delay: 0.7585s;
    -webkit-animation-delay: 0.7585s;
    -moz-animation-delay: 0.7585s;
  }
  #frotateG_08 {
    left: 9px;
    bottom: 9px;
    animation-delay: 0.845s;
    -o-animation-delay: 0.845s;
    -ms-animation-delay: 0.845s;
    -webkit-animation-delay: 0.845s;
    -moz-animation-delay: 0.845s;
  }
}

@keyframes f_fadeG {
  0% {
    background-color: rgb(0, 0, 0);
  }

  100% {
    background-color: rgb(255, 255, 255);
  }
}


@-o-keyframes f_fadeG {
  0% {
    background-color: rgb(0, 0, 0);
  }

  100% {
    background-color: rgb(255, 255, 255);
  }
}


@-ms-keyframes f_fadeG {
  0% {
    background-color: rgb(0, 0, 0);
  }

  100% {
    background-color: rgb(255, 255, 255);
  }
}


@-webkit-keyframes f_fadeG {
  0% {
    background-color: rgb(0, 0, 0);
  }

  100% {
    background-color: rgb(255, 255, 255);
  }
}


@-moz-keyframes f_fadeG {
  0% {
    background-color: rgb(0, 0, 0);
  }

  100% {
    background-color: rgb(255, 255, 255);
  }
}
</style>