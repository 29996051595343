<template>
  <div class="available-works">
    <div class="container">
      <IntroSection
          text="Welcome to my Available Works Collection!<br><br>
          Would you like to make an inquiry or express your interest? Please feel free to reach out to me through my contact form - <a href='/contact'>Contact</a>.<br><br>
          I look forward to hearing from you soon."
      >
      </IntroSection>
      <div class="submenu">
        <button id="btn-50-150" class="selected" v-on:click="scrollToSection($event)">$50 - $200</button>
        <button id="btn-200-400" v-on:click="scrollToSection($event)">$250 - $400</button >
        <button id="btn-500-800" v-on:click="scrollToSection($event)">$450+</button >
      </div>
      <!--  Price Section $50 - $200 -->
      <div class="price-section" id="section-50-150">
        <!--  id="50-150">$50 - $150</h2> -->
        <FlipGrid
            :imageSrc="require('@/assets/images/flowers/flowers-55.jpg')"
            imageDimensions='12" x 15"'
            imageMedium="acrylic"
            imageSubstrate="watercolour paper framed"
            imageTitle="Blue Pot"
            imagePrice="$200"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-81.jpg')"
            imageDimensions='8" x 10"'
            imageMedium="mixed media collage"
            imageSubstrate="board"
            imageTitle="lady with flower"
            imagePrice="$200"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-80.jpg')"
            imageDimensions='8" x 10"'
            imageMedium="mixed media"
            imageSubstrate="board"
            imageTitle="bug on a board"
            imagePrice="$150"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-75.jpg')"
            imageDimensions='8" x 10"'
            imageMedium="acrylic"
            imageSubstrate="board"
            imageTitle="lady in the shadows"
            imagePrice="$150"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-74.jpg')"
            imageDimensions='8" x 10"'
            imageMedium="acrylic"
            imageSubstrate="board"
            imageTitle="fashionistas 1"
            imagePrice="$150"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-76.jpg')"
            imageDimensions='8" x 10"'
            imageMedium="acrylic"
            imageSubstrate="board"
            imageTitle="fashionistas 2"
            imagePrice="$150"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-73.jpg')"
            imageDimensions='8" x 10"'
            imageMedium="acrylic"
            imageSubstrate="board"
            imageTitle="black pot"
            imagePrice="$120"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-67.jpg')"
            imageDimensions='8" x 8"'
            imageMedium="acrylic"
            imageSubstrate="board"
            imageTitle="painted vase 1"
            imagePrice="$100"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-68.jpg')"
            imageDimensions='8" x 8"'
            imageMedium="acrylic"
            imageSubstrate="board"
            imageTitle="painted vase 2"
            imagePrice="$100"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-69.jpg')"
            imageDimensions='8" x 8"'
            imageMedium="acrylic"
            imageSubstrate="board"
            imageTitle="painted vase 3"
            imagePrice="$100"
        />
        <!-- <FlipGrid
            :imageSrc="require('@/assets/images/faces/faces-8.jpg')"
            imageDimensions='12" x 15" Framed'
            imageMedium="mixed media"
            imageSubstrate="watercolour paper"
            imageTitle="Ringlets"
            imagePrice="$150"
        /> -->
        <FlipGrid
            :imageSrc="require('@/assets/images/flowers/flowers-33.jpg')"
            imageDimensions='8" x 8" Framed'
            imageMedium="mixed media"
            imageSubstrate="paper"
            imageTitle="Blue Vase"
            imagePrice="$150"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-4.jpg')"
            imageDimensions='12" x 6" x 3"'
            imageMedium="assemblage"
            imageSubstrate="mounted on board"
            imageTitle="Tall Towers"
            imagePrice="$150"
        />
       <!--  <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-49.jpg')"
            imageDimensions='17" x 20"'
            imageMedium="collage"
            imageSubstrate="art paper"
            imageTitle="bird on a limb"
            imagePrice="$200" -->
            
        />
        <!--  <FlipGrid
           :imageSrc="require('@/assets/images/misc/misc-52.jpg')"
           imageDimensions='17" x 20"'
           imageMedium="collage"
           imageSubstrate="art paper"
           imageTitle="parrot"
           imagePrice="$200"
       />
       <FlipGrid
           :imageSrc="require('@/assets/images/misc/misc-48.jpg')"
           imageDimensions='17" x 20"'
           imageMedium="collage"
           imageSubstrate="art paper"
           imageTitle="Rooster"
           imagePrice="$200" -->

       />
       <FlipGrid
           :imageSrc="require('@/assets/images/misc/misc-46.jpg')"
           imageDimensions='8" x 11"'
           imageMedium="collage"
           imageSubstrate="watercolour/ink"
           imageTitle="Pointed in all Directions"
           imagePrice="$150"
       />
        <!-- <FlipGrid
            :imageSrc="require('@/assets/images/flowers/flowers-25.jpg')"
            imageDimensions='16" x 20"'
            imageMedium="mixed media"
            imageSubstrate="canvas board"
            imageTitle="White Swirls"
            imagePrice="$200"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/faces/faces-50.jpg')"
            imageDimensions='16" x 20"'
            imageMedium="mixed media"
            imageSubstrate="canvas board"
            imageTitle="Tall Trio"
            imagePrice="$200"

        /> -->
        <FlipGrid
            :imageSrc="require('@/assets/images/flowers/flowers-46.jpg')"
            imageDimensions='8" x 10"'
            imageMedium="mixed media"
            imageSubstrate="board"
            imageTitle="Three's A Crowd"
            imagePrice="$100"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/flowers/flowers-53.jpg')"
            imageDimensions='8" x 10"'
            imageMedium="acrylic"
            imageSubstrate="wood panel"
            imageTitle="red blooms"
            imagePrice="$150"
        />
      </div>
      <!--  Price Section $250 - $400 -->
      <div class="price-section hidden" id="section-200-400">
        <!--  <h2 id="200-400">$200 - $400</h2> -->
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-79.jpg')"
            imageDimensions='12" x 12"'
            imageMedium="acrylic"
            imageSubstrate="board"
            imageTitle="Pink Ears"
            imagePrice="$400"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-72.jpg')"
            imageDimensions='15" x 22"'
            imageMedium="watercolour/ink"
            imageSubstrate="paper framed"
            imageTitle="two tall trees"
            imagePrice="$400"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/flowers/flowers-54.jpg')"
            imageDimensions='12" x 16"'
            imageMedium="acrylic"
            imageSubstrate="board"
            imageTitle="pink and blue"
            imagePrice="$300"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/houses/structures-7.jpg')"
            imageDimensions='10" x 12"'
            imageMedium="charcoal"
            imageSubstrate="paper - framed"
            imageTitle="grey roof"
            imagePrice="$250"
        />
        <!-- <FlipGrid
             :imageSrc="require('@/assets/images/misc/misc-64.jpg')"
             imageDimensions='22" x 29"'
             imageMedium="watercolour/ink"
             imageSubstrate="paper - framed"
             imageTitle="pink flamingo"
             imagePrice="$400"
         /> -->
         <FlipGrid
             :imageSrc="require('@/assets/images/misc/misc-63.jpg')"
             imageDimensions='12" x 24"'
             imageMedium="mixed media"
             imageSubstrate="canvas panel"
             imageTitle="deer"
             imagePrice="$250"
         />
         <FlipGrid
             :imageSrc="require('@/assets/images/misc/misc-59.jpg')"
             imageDimensions='12" x 12"'
             imageMedium="acrylic"
             imageSubstrate="museum canvas"
             imageTitle="3 hats"
             imagePrice="$250"
         />
         <FlipGrid
             :imageSrc="require('@/assets/images/flowers/flowers-47.jpg')"
             imageDimensions='20" x 22"'
             imageMedium="mixed media"
             imageSubstrate="art paper, framed"
             imageTitle="Red with Blue"
             imagePrice="$400"
         />
         <!-- <FlipGrid
             :imageSrc="require('@/assets/images/houses/houses-1.jpg')"
             imageDimensions='20" x 22"'
             imageMedium="acrylic"
             imageSubstrate="art paper/framed"
             imageTitle="Orange Scape"
             imagePrice="$250"
         />-->
         <FlipGrid
             :imageSrc="require('@/assets/images/misc/misc-39.jpg')"
             imageDimensions='16" x 20"'
             imageMedium="mixed media/collage"
             imageSubstrate="canvas board"
             imageTitle="Funny Bike"
             imagePrice="$350"

         />
         <FlipGrid
             :imageSrc="require('@/assets/images/misc/misc-53.jpg')"
             imageDimensions='12" x 12"'
             imageMedium="mixed media"
             imageSubstrate="gallery canvas"
             imageTitle="Hammock"
             imagePrice="$350"
         />
         <FlipGrid
             :imageSrc="require('@/assets/images/faces/faces-60.jpg')"
             imageDimensions='16" x 20"'
             imageMedium="mixed media"
             imageSubstrate="canvas board"
             imageTitle="Green Shadow"
             imagePrice="$400"

         />
         <FlipGrid
             :imageSrc="require('@/assets/images/faces/faces-65.jpg')"
             imageDimensions='20" x 16"'
             imageMedium="mixed media"
             imageSubstrate="canvas board"
             imageTitle="French Script"
             imagePrice="$400"
         />
         <FlipGrid
             :imageSrc="require('@/assets/images/flowers/flowers-38.jpg')"
             imageDimensions='17" x 21"'
             imageMedium="mixed media"
             imageSubstrate="art/paper framed"
             imageTitle="Yellow Pot"
             imagePrice="$350"
         />
         <FlipGrid
             :imageSrc="require('@/assets/images/misc/misc-47.jpg')"
             imageDimensions='30" x 22"'
             imageMedium="acrylic mixed media"
             imageSubstrate="paper"
             imageTitle="Tanker 2"
             imagePrice="$400"
         />
         <FlipGrid
             :imageSrc="require('@/assets/images/misc/misc-21.jpg')"
             imageDimensions='20" x 13"'
             imageMedium="collage/mixed media"
             imageSubstrate="watercolour paper/framed"
             imageTitle="Whimsy"
             imagePrice="$300"
         />
         <FlipGrid
             :imageSrc="require('@/assets/images/flowers/flowers-32.jpg')"
             imageDimensions='28" x 20"'
             imageMedium="mixed media"
             imageSubstrate="paper"
             imageTitle="window bouquet"
             imagePrice="$400"
         />
         <FlipGrid
             :imageSrc="require('@/assets/images/misc/misc-34.jpg')"
             imageDimensions='28" x 20"'
             imageMedium="collage/mixed media"
             imageSubstrate="watercolour paper"
             imageTitle="Clowning Around"

             imagePrice="$300"
         />
        <FlipGrid
            :imageSrc="require('@/assets/images/faces/portraits-of-ladies-on-powder-room-wall--canvas-20x24-1.jpg')"
            imageDimensions='16" x 20"'
            imageMedium="acrylic"
            imageSubstrate="canvas board"
            imageTitle="Profile #1"
            imagePrice="$295"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/faces/portraits-of-ladies-on-powder-room-wall--canvas-20x24-3.jpg')"
            imageDimensions='16" x 20"'
            imageMedium="acrylic"
            imageSubstrate="canvas board"
            imageTitle="Profile #2"
            imagePrice="$295"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/faces/portraits-of-ladies-on-powder-room-wall--canvas-20x24-2.jpg')"
            imageDimensions='16" x 20"'
            imageMedium="acrylic"
            imageSubstrate="canvas board"
            imageTitle="Profile #3"
            imagePrice="$295"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/flowers/flowers-36.jpg')"
            imageDimensions='17" x 20"'
            imageMedium="mixed media"
            imageSubstrate="paper"
            imageTitle="flowers on a stack"
            imagePrice="$250"

        />
        <FlipGrid
            :imageSrc="require('@/assets/images/flowers/negative-space-flowers-1.jpg')"
            imageDimensions='16" x 20"'
            imageMedium="mixed media/ink"
            imageSubstrate="canvas board"
            imageTitle="Full Bloom"
            imagePrice="$250"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/flowers/flowers-40.jpg')"
            imageDimensions='20" x 16"'
            imageMedium="mixed media/collage"
            imageSubstrate="canvas board"
            imageTitle="White Lily"
            imagePrice="$250"

        />
        <FlipGrid
            :imageSrc="require('@/assets/images/flowers/flowers-41.jpg')"
            imageDimensions='12" x 24"'
            imageMedium="acrylic/ink"
            imageSubstrate="canvas board"
            imageTitle="Hot Pink Pot"
            imagePrice="$300"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/faces/large-portrait-in-my-lounge-deb-weirers-style.jpg')"
            imageDimensions='29" x 22"'
            imageMedium="mixed media"
            imageSubstrate="watercolour paper"
            imageTitle="Black-haired Beauty"
            imagePrice="$250"
        />
      </div>
      <!--  Price Section $450+ -->
      <div class="price-section hidden" id="section-500-800">
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-78.jpg')"
            imageDimensions='16" x 16"'
            imageMedium="acrylic"
            imageSubstrate="museum canvas"
            imageTitle="Orange Ears"
            imagePrice="$500"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-70.jpg')"
            imageDimensions='35" x 35"'
            imageMedium="acrylic"
            imageSubstrate="canvas"
            imageTitle="pendants"
            imagePrice="$800"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-20.jpg')"
            imageDimensions='16" x 20"'
            imageMedium="collage/mixed media"
            imageSubstrate="board"
            imageTitle="Grazer"
            imagePrice="$500"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-66.jpg')"
            imageDimensions='54" x 42"'
            imageMedium="acrylic"
            imageSubstrate="framed canvas"
            imageTitle="3 Chairs"
            imagePrice="$1500"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-62.jpg')"
            imageDimensions='16" x 20"'
            imageMedium="mixed media"
            imageSubstrate="canvas board"
            imageTitle="Walk in the rain"
            imagePrice="$450"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/flowers/flowers-49.jpg')"
            imageDimensions='16" x 20"'
            imageMedium="mixed media"
            imageSubstrate="board"
            imageTitle="Mixed Bouquet"
            imagePrice="$450"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-58.jpg')"
            imageDimensions='30" x 30"'
            imageMedium="mixed media"
            imageSubstrate="canvas board"
            imageTitle="Tennis, Anyone?"
            imagePrice="$1000"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/flowers/flowers-34.jpg')"
            imageDimensions='10" x 20"'
            imageMedium="mixed media"
            imageSubstrate="gallery canvas"
            imageTitle="Hanging On"
            imagePrice="$500"
            tall
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/faces/faces-61.jpg')"
            imageDimensions='16" x 16"'
            imageMedium="mixed media"
            imageSubstrate="museum board"
            imageTitle="Red Accents"
            imagePrice="$450"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-36.jpg')"
            imageDimensions='24" x 14"'
            imageMedium="mixed media/collage"
            imageSubstrate="board"
            imageTitle="The Station"
            
            imagePrice="$450"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/flowers/flowers-35.jpg')"
            imageDimensions='10" x 20"'
            imageMedium="mixed media"
            imageSubstrate="gallery canvas"
            imageTitle="Off the Edge"
            imagePrice="$500"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/houses/structures-3.jpg')"
            imageDimensions='22" x 22"'
            imageMedium="collage mixed media"
            imageSubstrate="board"
            imageTitle="some circles"
            imagePrice="$600"
            
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/faces/faces-63.jpg')"
            imageDimensions='12" x 35"'
            imageMedium="museum canvas"
            imageSubstrate="mixed media"
            imageTitle="Polka Dots"
            imagePrice="$600"
            tall
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/faces/faces-53.jpg')"
            imageDimensions='30" x 13" Framed'
            imageMedium="acrylic mixed media"
            imageSubstrate="on paper"
            imageTitle="Long and Lean 2"
            imagePrice="$500"
            
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/houses/IMG_3366.jpg')"
            imageDimensions='48" x 24"'
            imageMedium="mixed media"
            imageSubstrate="museum canvas"
            imageTitle="Night Sky"
            imagePrice="$700"
            
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-55.jpg')"
            imageDimensions='20" x 25"'
            imageMedium="mixed media collage"
            imageSubstrate="framed board"
            imageTitle="3 pelicans"
            imagePrice="$450"
            
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-2.jpg')"
            imageDimensions='16" x 16"'
            imageMedium="mixed media"
            imageSubstrate="museum board"
            imageTitle="A Boat"
            imagePrice="$450"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/flowers/flowers-51.jpg')"
            imageDimensions='24" x 12"'
            imageMedium="mixed media"
            imageSubstrate="museum canvas"
            imageTitle="The Loner"
            imagePrice="$500"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/flowers/flowers-50.jpg')"
            imageDimensions='24" x 12"'
            imageMedium="mixed media"
            imageSubstrate="museum canvas"
            imageTitle="Purple Haze"
            imagePrice="$500"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/flowers/flowers-52.jpg')"
            imageDimensions='24" x 12"'
            imageMedium="mixed media"
            imageSubstrate="museum canvas"
            imageTitle="Yellow Trio"
            imagePrice="$500"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/houses/houses-11.jpg')"
            imageDimensions='24" x 12"'
            imageMedium="mixed media"
            imageSubstrate="museum canvas"
            imageTitle="3 houses"
            imagePrice="$500"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/faces/faces-67.jpg')"
            imageDimensions='20" x 20"'
            imageMedium="acrylic"
            imageSubstrate="board"
            imageTitle="redhead #2"
            imagePrice="$500"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-83.jpg')"
            imageDimensions='12" x 30"'
            imageMedium="acrylic"
            imageSubstrate="board"
            imageTitle="fore"
            imagePrice="$500"
        />
        <FlipGrid
            :imageSrc="require('@/assets/images/misc/misc-82.jpg')"
            imageDimensions='32" x 42"'
            imageMedium="mixed media"
            imageSubstrate="framed slate"
            imageTitle="tree in front"
            imagePrice="$800"
        />
      </div>
      <BackToTopButton/>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import FlipGrid from "@/components/FlipGrid";
import IntroSection from "@/components/IntroSection";
import BackToTopButton from "@/components/BackToTopButton";

export default {
  components: {FlipGrid, BackToTopButton, IntroSection},
  data() {
    return {
      numsArray: [],
    }
  },
  created() {
    const metaDescription = this.$route.meta.metaDescription;
    document.querySelector('meta[name="description"]').setAttribute('content', metaDescription);
    const metaTitle = this.$route.meta.title;
    const titleElement = document.querySelector('title');
    titleElement.textContent = metaTitle;
  },
  methods: {
    randomize() {
      let priceSections = document.querySelectorAll('.price-section');

      priceSections.forEach((priceSection) => {
        let flipGrids = Array.from(priceSection.querySelectorAll('.flip-grid'));

        this.shuffleArray(flipGrids);

        priceSection.innerHTML = '';

        flipGrids.forEach((flipGrid, index) => {
          if (index === 0 || index % 2 === 0) {
            flipGrid.classList.add('right');
          }
          priceSection.appendChild(flipGrid);
        });

      });
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    },
    scrollToSection(event) {
      $('.submenu button').removeClass('selected');
      event.target.classList.add('selected');

      $('.price-section').addClass('hidden');

      if(event.target.id === 'btn-50-150') {
        $('#section-50-150').removeClass('hidden');
      }
      else if(event.target.id === 'btn-200-400') {
        $('#section-200-400').removeClass('hidden');
      }
      else if(event.target.id === 'btn-500-800') {
        $('#section-500-800').removeClass('hidden');
      }
    },
    addTall() {
      let priceSections = document.querySelectorAll('.price-section');

      priceSections.forEach((priceSection) => {
        let flipGrids = priceSection.querySelectorAll('.flip-grid');
        flipGrids.forEach((flipGrid) => {
          let img = flipGrid.querySelector('.image-container .art-image');

          img.onload = function() {
            var width = img.width;
            var height = img.height;
            var aspectRatio = width / height;

            if (aspectRatio < 0.7) {
              flipGrid.classList.add('tall');
            }
          };
        });
      });
    }
  },
  mounted() {
    this.randomize();
    this.addTall();
  },
}
</script>
<style lang="scss">
.available-works {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;

  @include for-tablet-portrait-down {
    padding-top: 0;
  }
  @include for-phone-only {
    padding-top: 1.5rem;
  }

  .container {
    padding: 0 !important;

    .intro {
      background-color: #f5f4f0;
      padding: 20px 0;

      .container {
        padding: 0 1.5rem !important;
      }

      a {
        font-weight: bold;
        color: black;

        &:hover {
          color: #2c3e50;
        }
      }
    }

    .submenu {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 4rem;
      margin-top: 4rem;
      gap: 3rem;

      @include for-phone-only {
        gap: 2.5rem;
      }

      button {
        color: #2c3e50;
        background-color: white;
        border: none;
        font-size: 18px;
        font-family: Lato, sans-serif;
        font-weight: 400;
        padding-left: 0;
        padding-right: 0;
        cursor: pointer;
        transition: font-weight .4s ease;
        position: relative;

        @include for-phone-only {
          font-size: 15px;
        }

        &::after {
          content: "";
          position: absolute;
          height: 1.6px;
          background-color: #2c3e50;
          left: 0;
          bottom: -0.25rem;
          width: 100%;
          transform: scale(0);
          transition: transform .5s ease;
          transform-origin: left;
        }

        &:hover {
          &::after {
            transform: scale(1);
            height: 1.6px;
          }
        }

        &.selected {
          pointer-events: none;
          &::after {
            content: "";
            position: absolute;
            height: 1.6px;
            background-color: #2c3e50;
            bottom: -0.25rem;
            width: 100%;
            transform: scale(1);
          }
        }
      }

      @media (min-width: 400px) {
        justify-content: center;
        gap: 2.5rem;
      }

      @include for-tablet-landscape-up {
        gap: 7rem;
        margin-bottom: 3rem;

        button {
          font-size: 22px;
        }
      }
    }

    .price-section {
      display: flex;
      flex-direction: column;
    }

    h2 {
      font-size: 35px;
      margin-bottom: 0.5rem;
      padding-bottom: 0;
      font-family: Lato, sans-serif;
      font-weight: 400;
      text-transform: capitalize;
    }
    h3 {
      font-size: 30px;
    }
    .hidden {
      display: none;
    }

    @include for-tablet-portrait-down {
      h2 {
        font-size: 25px;
        padding-bottom: 0;
        font-family: Lato, sans-serif;
        font-weight: 400;
        text-transform: capitalize;
        line-height: 1;
        margin-bottom: 0;
      }
      h3 {
        font-size: 20px;
        margin-bottom: 0;
      }
    }
  }
}
</style>
