<template>
  <section class="intro-section">
    <div class="intro full-width">
      <div class="container">
        <p v-html="text"></p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'IntroSection',
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.intro-section {
  padding: 20px 0;
  .intro {
    background-color: #f5f4f0;
    padding: 20px 0;

    .container {
      padding: 0 1.5rem !important;
    }

    a {
      font-weight: bold;
      color: black;

      &:hover {
        color: #2c3e50;
      }
    }
  }
}
@media (min-width: 1010px) {
  .intro-section.collection-view {
    display: none;
  }
}
</style>
